var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-container",attrs:{"id":"course-container"}},[(_vm.showSearchField)?_c('div',[_c('div',{staticStyle:{"display":"flex"}},[_c('v-text-field',{style:({ width: _vm.returnInputWidth(), height: 50 + 'px' }),attrs:{"label":"Solo","placeholder":_vm.$t('message.search'),"solo":""},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}})],1)]):_vm._e(),(_vm.show)?_c('div',{staticClass:"courses-container main-container",style:({ width: _vm.returnPageWidth() })},_vm._l((_vm.filteredCourses.slice(
        0,
        _vm.showAll ? _vm.filteredCourses.length : 6
      )),function(course,index){return _c('Course',{key:index,attrs:{"courseinfo":_vm.courseInfo(course),"has-discounts":_vm.hasDiscounts},on:{"openPaymentDialog":function($event){return _vm.openDibsDialog($event)},"payCompleted":function($event){return _vm.closeDibsDialog($event)}}})}),1):_vm._e(),(!_vm.show)?_c('div',{staticClass:"courses-container"},_vm._l((_vm.filteredCourses.slice(
        0,
        _vm.showAll ? _vm.filteredCourses.length : 6
      )),function(course,index){return _c('course-table',{key:'B' + index,attrs:{"courseinfo":_vm.courseInfo(course),"has-discounts":_vm.hasDiscounts},on:{"openPaymentDialog":function($event){return _vm.openDibsDialog($event)},"payCompleted":function($event){return _vm.closeDibsDialog($event)}}})}),1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredCourses.length > 6),expression:"filteredCourses.length > 6"}],staticClass:"mt-4",on:{"click":function($event){_vm.showAll = !_vm.showAll}}},[_vm._v(_vm._s(_vm.showAll ? _vm.$t("message.showfewercourses") : _vm.$t("message.showmorecourses")))]),_c('v-dialog',{model:{value:(_vm.dibsPaymentDialog),callback:function ($$v) {_vm.dibsPaymentDialog=$$v},expression:"dibsPaymentDialog"}},[_c('div',{attrs:{"id":"dibs-frame"}})]),(_vm.purchasedCourseInfo)?_c('course-dialog',{ref:"purchasedCourseDialog",attrs:{"course-info":_vm.purchasedCourseInfo,"has-discounts":_vm.hasDiscounts}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.paymentFailed),callback:function ($$v) {_vm.paymentFailed=$$v},expression:"paymentFailed"}},[_vm._v(" "+_vm._s(_vm.$t("message.paymentFailed"))+" ")]),(_vm.dialogCongrats)?_c('v-dialog',{attrs:{"width":"35%","value":"true"}},[_c('v-card',[_c('v-card-title',{staticClass:"green lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("message.paymentSuccessTitle"))+" ")]),_c('v-card-text',[_c('div',{staticClass:"pb-3"},[_c('v-icon',{attrs:{"color":"green darken-2","x-large":""}},[_vm._v("check")]),_vm._v(" "+_vm._s(_vm.$t("message.paymentSuccessText"))+" ")],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.dialogCongrats = false}}},[_vm._v(" Ok ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }