<template>
  <div class="container">
    <div class="img" :class="{ 'course-img': $vuetify.breakpoint.xs }">
      <img :src="this.courseinfo.backgroundImageUrl" />
    </div>
    <div
      class="headline course-items-text"
      :class="{
        'course-title': $vuetify.breakpoint.smAndDown,
        subheading: $vuetify.breakpoint.smAndDown,
      }"
    >
      <p>{{ this.courseinfo.name }}</p>
    </div>
    <div
      class="course-items-text"
      :class="{ description: $vuetify.breakpoint.smAndDown }"
    >
      <p>{{ removePTags(this.courseinfo.description) }}</p>
    </div>
    <course-dialog
      ref="CourseDialogComp"
      :course-info="courseinfo"
      :has-discounts="hasDiscounts"
    />
    <buy-course-dialog
      v-model="buyDialog"
      :course-info="courseinfo"
      @close="buyDialog = false"
      @openPaymentDialog="$emit('openPaymentDialog', $event)"
      @payCompleted="$emit('payCompleted', $event)"
      :has-discounts="hasDiscounts"
    />

    <v-btn
      v-if="!print"
      class="start-course-btn"
      flat
      @click="openDialog()"
      color="blue"
      >{{ $t("message.start") }}</v-btn
    >
    <v-btn
      v-else
      class="start-course-btn my-0"
      flat
      @click="openBuyDialog()"
      color="blue"
      >{{ $t("message.buy") }}</v-btn
    >

    <!--    <v-btn v-if="courseinfo.forFree" class="start-course-btn" flat @click="openDialog()" color="blue">{{$t('message.start')}}</v-btn>-->
    <!--    <v-btn v-else-if="purchased" flat disabled>{{ $t("message.alreadyPurchased") }}</v-btn>-->
    <!--    <v-btn v-else class="start-course-btn my-0" flat @click="openBuyDialog()" color="blue">{{$t('message.buy')}}</v-btn>-->
    <span v-show="!price"
      ><span class="bold-font">{{ $t("message.price") }}:</span
      ><span class="green">{{ $t("message.free") }}</span></span
    >
    <span v-show="price"
      ><span class="bold-font">{{ $t("message.price") }}:</span
      ><span class="price">{{ price | filterAmount }}</span> SEK</span
    >
  </div>
</template>

<script>
import CourseDialog from './CourseDialog'
import BuyCourseDialog from './BuyCourseDialog'

export default {
  name: 'CourseTable',
  components: {
    BuyCourseDialog,
    CourseDialog
  },
  props: {
    courseinfo: {
      type: Object,
      required: true
    },
    hasDiscounts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      buyDialog: false
    }
  },
  computed: {
    purchased () {
      const { purchaserData } = this.courseinfo
      return purchaserData
    }
  },
  methods: {
    openDialog () {
      this.$refs['CourseDialogComp'].open()
    },
    removePTags (desc) {
      if (desc.split('')[0] === '<') return desc.replace(/<\/?[^>]+(>|$)/g, '')
      return desc
    },
    openBuyDialog () {
      this.buyDialog = true
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #bbbbbb;
}

.course-items-text {
  flex-grow: 1;
  text-align: start;
  width: calc((100% - 200px) / 2);
}

p {
  padding-left: 10px;
  margin-bottom: 0px;
  text-align: start;
}

img {
  height: 100px;
  flex-grow: 0;
  border-radius: 2px;
  box-shadow: 0px 1px 2px grey;
}

.start-course-btn {
  width: 100px;
  text-align: center;
  justify-content: center;
  flex-grow: 0;
}

.description {
  display: none;
}

.course-title {
  text-align: center;
  justify-content: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  font-size: 15px;
}

.course-img {
  display: none;
}

.bold-font {
  font-weight: bold;
}
.green {
  color: green;
}
.price {
  font-size: larger;
  font-style: italic;
}
</style>
