<template>
  <v-app id="app">
    <Coursepage id="coursepage" />
    <router-view/>
  </v-app>
</template>

<script>
import Coursepage from './components/Coursepage'
import nets from './payment'

export default {
  name: 'App',
  components: {
    Coursepage
  },
  data () {
    return {
      ids: ['coursepage'],
      dialog: true
    }
  },
  mounted () {
    let dibsPaymentScript = document.createElement('script')
    if (nets.isTestPayment()) {
      dibsPaymentScript.setAttribute('src', 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1')
    } else {
      dibsPaymentScript.setAttribute('src', 'https://checkout.dibspayment.eu/v1/checkout.js?v=1')
    }
    document.head.appendChild(dibsPaymentScript)
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  max-width: 100%;
}
</style>
