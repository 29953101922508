<template>
  <div :class="{ blackout: value }">
    <v-dialog
      :value="value"
      width="600"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="onCancel"
      persistent
    >
      <v-card>
        <v-img height="250" :src="courseInfo.backgroundImageUrl"></v-img>
        <v-card-title
          class="title text-break"
          :class="{
            'font-italic': !courseInfo.name,
            'grey--text': !courseInfo.name,
          }"
        >
          {{ courseInfo.name }}
        </v-card-title>
        <v-card-text align="left">
          <text-expander
            :key="courseInfo.courseId"
            class="px-2"
            font-size-class="body-2"
            :expand-character-limit="10"
          >
            <div class="subtitle pb-2" v-html="courseInfo.description" />
          </text-expander>
          <v-divider />
        </v-card-text>

        <v-card-text>
          <div class="pb-3">{{ $t("message.signuptext") }}</div>
          <v-alert
            type="error"
            class="py-1"
            color="red"
            :value="errors.length > 0"
          >
            {{ $t("message.baloorequired") }}:
            <span v-for="(error, index) in errors" :key="index">
              {{ error }}
            </span>
          </v-alert>
          <v-flex
            style="display: flex; flex-direction: column; align-items: center"
            xs12
            class="pt-2"
          >
            <v-card
              style="width: 90%; flex-grow: 0"
              v-if="hasDiscounts"
              class="mb-4"
            >
              <v-card-text>
                <div v-if="discountPercentage">
                  <div class="text-center subtitle-1 text--disabled">
                    {{ discountCode }}
                    <div
                      class="
                        text-center
                        title
                        font-weight-bold
                        green--text
                        mt-1
                        mb-2
                      "
                    >
                      <span
                        >{{ discountPercentage / 100 }}%
                        {{ $t("message.discount") }}</span
                      >
                    </div>
                  </div>
                  <v-btn
                    block
                    color="red"
                    @click="resetDiscountCode"
                    class="white--text"
                    >{{ $t("message.removeDiscount") }}</v-btn
                  >
                </div>
                <div v-else>
                  <v-text-field
                    v-model="discountCode"
                    class="pa-0"
                    :label="$t('message.discountCode')"
                    :disabled="checkingDiscountCode"
                  ></v-text-field>
                  <v-btn
                    block
                    color="blue"
                    :loading="checkingDiscountCode"
                    class="white--text"
                    @click="validateDiscountCode"
                    >{{ $t("message.applyDiscount") }}</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-text-field
              v-model="email"
              class="pa-0"
              :label="$t('message.email')"
              style="width: 90%; flex-grow: 0"
              name="email"
              aria-autocomplete="on"
              autofocus
            ></v-text-field>
            <v-text-field
              v-model="firstName"
              class="pa-0"
              :label="$t('message.firstname')"
              style="width: 90%; flex-grow: 0"
              name="firstname"
              aria-autocomplete="on"
            ></v-text-field>
            <v-text-field
              v-model="lastName"
              class="pa-0"
              :label="$t('message.lastname')"
              style="width: 90%; flex-grow: 0"
              name="lastname"
            ></v-text-field>
            <v-checkbox
              v-show="false"
              v-model="byInvoice"
              :label="$t('message.byInvoice')"
              class="checkBox"
              color="primary"
            ></v-checkbox>
            <v-text-field
              v-model="companyNumber"
              v-show="byInvoice"
              class="pa-0"
              :label="$t('message.companyNumber')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-text-field
              v-model="companyEmail"
              v-show="byInvoice"
              class="pa-0"
              :label="$t('message.companyEmail')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-text-field
              v-model="reference"
              v-show="byInvoice"
              class="pa-0"
              :label="$t('message.reference')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-checkbox
              v-model="isTic"
              v-show="byInvoice"
              :label="$t('message.isTic')"
              class="checkBox"
              color="primary"
            ></v-checkbox>
          </v-flex>
        </v-card-text>

        <v-card-text align="left">
          <div class="subtitle pb-1">
            {{ $t("message.numberOfCourses") }}
          </div>
          <div class="subTitle">
            {{ $t("message.price") }}
            <span class="font-weight-bold"
              >{{ courseInfo.price | filterAmount }}
              {{ $t("message.sek") }}</span
            >
          </div>
          <div class="subTitle" v-if="discountPercentage">
            {{ $t("message.discount") }}
            <span class="font-weight-bold green--text"
              >{{ (courseInfo.price - priceWithDiscount) | filterAmount }}
              {{ $t("message.sek") }}</span
            >
          </div>
          <div class="subTitle">
            {{ $t("message.vat") }}
            <span class="font-weight-bold"
              >{{ vat | filterAmount }} {{ $t("message.sek") }}</span
            >
          </div>
          <div class="title pt-4">
            <span class="font-weight-regular">{{ $t("message.toPay") }} </span
            ><span class="font-weight-bold"
              >{{ (this.priceWithDiscount + vat) | filterAmount }}
              {{ $t("message.sek") }}</span
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn id="cancel" flat @click="onCancel">
            {{ $t("message.cancel") }}
          </v-btn>
          <v-btn
            id="buy"
            color="blue"
            class="white--text"
            @click="validateUserAndProceed"
          >
            {{ $t("message.proceedToCheckout") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextExpander from './TextExpander'
import nets from './../payment'

const validateEmail = (email) => {
  if (!email) return false
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export default {
  name: 'BuyCourseDialog',
  components: {
    TextExpander
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    courseInfo: {
      type: Object,
      required: true
    },
    hasDiscounts: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (value) {
      if (value) {
        this.$trackEvent({
          eventCategory: 'course',
          eventAction: 'view',
          eventLabel: this.courseInfo.courseId
        })
      }
    }
  },
  data () {
    return {
      checkoutSnackbar: false,
      paymentId: '',
      hasLoaded: false,
      email: '',
      firstName: '',
      lastName: '',
      errors: [],
      isTic: false,
      byInvoice: false,
      companyNumber: '',
      companyEmail: '',
      reference: '',
      discountCode: '',
      checkingDiscountCode: false,
      discountPercentage: false
    }
  },
  computed: {
    vat () {
      return this.priceWithDiscount / 4
    },
    priceWithDiscount () {
      if (this.discountPercentage) {
        return (
          this.courseInfo.price * ((10000 - this.discountPercentage) / 10000)
        )
      }

      return this.courseInfo.price
    }
  },
  methods: {
    validateUserAndProceed () {
      this.errors = []
      if (!validateEmail(this.email)) {
        this.errors.push(this.$i18n.locale === 'en' ? 'Email' : 'E-post')
      }

      if (!this.firstName || this.firstName.trim().length < 2) {
        this.errors.push(this.$i18n.locale === 'en' ? 'Firstname' : 'Förnamn')
      }

      if (!this.lastName || this.lastName.trim().length < 2) {
        this.errors.push(this.$i18n.locale === 'en' ? 'Lastname' : 'Efternamn')
      }

      if (this.errors.length > 0) return ''

      if (this.byInvoice) {
        if (!validateEmail(this.companyEmail)) {
          this.errors.push(
            this.$i18n.locale === 'en' ? 'Company E-mail' : 'Faktura e-mail'
          )
        }
        if (!this.companyNumber || this.companyNumber.trim().length < 2) {
          this.errors.push(
            this.$i18n.locale === 'en'
              ? 'Company Number'
              : 'Organisationsnummer'
          )
        }
        if (!this.isTic) {
          this.errors.push(
            this.$i18n.locale === 'en'
              ? 'I accept the terms of purchase'
              : 'Jag godkänner köpvillkoren'
          )
        }
        if (this.errors.length > 0) return ''
        this.makeInvoice()
      } else {
        this.onBuy()
      }
    },
    onBuy () {
      this.axios
        .post('create-payment', {
          type: 'card',
          price: this.priceWithDiscount,
          courseName: this.courseInfo.name,
          courseId: this.courseInfo.courseId,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName
        })
        .then((response) => {
          const { paymentId = '' } = response.data
          this.paymentId = paymentId
          this.initCheckout(paymentId)
        })
        .finally((_) => {
          this.$emit('openPaymentDialog', {
            id: this.paymentId,
            amount: this.priceWithDiscount
          })
          // this.$emit('onlyClose')
        })
    },
    makeInvoice () {
      this.axios
        .post('create-payment', {
          type: 'invoice',
          price: this.priceWithDiscount,
          courseName: this.courseInfo.name,
          courseId: this.courseInfo.courseId,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          companyEmail: this.companyEmail,
          companyNumber: this.companyNumber,
          reference: this.reference
        })
        .then((response) => {
          const { paymentId = '' } = response.data
          this.paymentId = paymentId
          const vm = this
          this.sendUser()
            .then((response) => {
              // eslint-disable-next-line handle-callback-err
              vm.$emit('payCompleted', true)
              vm.$emit('close')

              vm.$trackEvent({
                eventCategory: 'invoice',
                eventAction: 'completed',
                eventLabel: this.priceWithDiscount
              })
            })
            .catch((_) => {
              vm.errors.push('There is something went wrong')
              vm.$emit('payCompleted', false)
            })
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.dir(error)
        })
    },
    onCancel () {
      this.$emit('close')
    },
    initCheckout (paymentId) {
      const vm = this
      const checkoutKey = nets.getCheckoutKey()
      const checkoutOptions = {
        checkoutKey,
        paymentId,
        containerId: 'dibs-frame',
        language: this.$i18n.locale === 'en' ? 'en-GB' : 'sv-SE'
      }

      vm.$trackEvent({
        eventCategory: 'dibs',
        eventAction: 'created',
        eventLabel: this.priceWithDiscount
      })

      /* eslint-disable */
      let checkout = new Dibs.Checkout(checkoutOptions)
      if (nets.isTestPayment()) console.log('payment-checkout')
      /* eslint-enable */
      checkout.on('payment-completed', function (response) {
        // let paymentId = response['paymentId']
        vm.sendUser()
          .then((response) => {
            // eslint-disable-next-line handle-callback-err
            vm.$emit('payCompleted', true)
            vm.$emit('close')

            vm.$trackEvent({
              eventCategory: 'dibs',
              eventAction: 'completed',
              eventLabel: this.priceWithDiscount
            })
          })
          .catch((_) => {
            vm.errors.push('There is something went wrong')
            vm.$emit('payCompleted', false)
          })
      })
    },
    sendUser () {
      const targetRequest = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        contentId: this.courseInfo.courseId,
        contentName: this.courseInfo.name,
        contentType: this.courseInfo.contentType,
        paymentId: this.paymentId
      }
      return this.axios.post('targets', targetRequest)
    },
    validateDiscountCode () {
      if (!this.discountCode || this.discountCode.legth === 0) {
        alert(this.$t('message.emptyDiscountCode'))

        return
      }

      this.checkingDiscountCode = true

      this.axios
        .get(`check-discount/${encodeURI(this.discountCode)}`)
        .then(({ data: { discountPercentage } }) => {
          this.discountPercentage = discountPercentage
        })
        .finally(() => {
          this.checkingDiscountCode = false

          if (!this.discountPercentage) {
            alert(this.$t('message.noDiscountFound'))
          }
        })
    },
    resetDiscountCode () {
      this.checkingDiscountCode = false
      this.discountPercentage = false
      this.discountCode = ''
    }
  }
}
</script>

<style scoped>
.blackout {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 2;
}
.checkBox {
  color: #0d47a1;
  width: 100%;
  margin-left: 50px;
}
</style>
