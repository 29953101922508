export class Nets {
  constructor () {
    const myOwnTestKey = 'test-checkout-key-27201ace0f0e4687b97b8aa674c17c59' // todo move it to config
    this.defaultCheckoutKey = 'live-checkout-key-0977fb3cb5ac420288488f8e00499052' // this key is own Baloo

    this.checkoutKeys = new Map()
    this.checkoutKeys.set('localhost:8080', myOwnTestKey)
    this.checkoutKeys.set('dev-catalog.baloolearning.com', myOwnTestKey)
    // this.checkoutKeys.set('www.smartpsykiatri.se', 'HERE-WILL-BE-PERSONAL-KEY')

    // this.checkoutKeys.set('awesomecompany-demo.baloolearning.se', this.defaultCheckoutKey)
    // this.checkoutKeys.set('www.baloolearning.se', this.defaultCheckoutKey)
    // this.checkoutKeys.set('yellowelk.se', this.defaultCheckoutKey)
    // this.checkoutKeys.set('www.quantumleap.se', this.defaultCheckoutKey)

    // this.checkoutKeys.set('www.noxconsulting.se', 'baloo-acc')
    // this.checkoutKeys.set('www.nox-community.se', 'baloo-acc')
    // this.checkoutKeys.set('foxigs.wixsite.com', '')
  }

  /**
   * Get original Customer domain
   * @returns {boolean}
   */
  isTestPayment () {
    let host = encodeURI(this.getCustomerHost())
    return host === 'localhost:8080' ||
      host === 'dev-catalog.baloolearning.com'
  }

  getCheckoutKey () {
    let host = this.getCustomerHost()
    if (this.checkoutKeys.has(host)) return this.checkoutKeys.get(host)
    return this.defaultCheckoutKey
  }

  /**
   * Get original Customer domain
   * @returns {url}
   */
  getCustomerHost () {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('academyId') && urlParams.get('academyId').length) {
      return `${urlParams.get('academyId')}.baloolearning.com`
    }

    return (window.location !== window.parent.location) ? new URL(document.referrer).host : document.location.host
  }
}

export default new Nets()
