import SimpleAnalytics from 'simple-analytics-vue'
import Vue from 'vue'

export default function install (customerId) {
  // Overwrite host where data is collected in SA.
  window.sa_settings = {
    hostname: process.env.VUE_APP_HOST
  }

  // Add customerId as metadata.
  window.sa_metadata = {
    customerId
  }

  // Set placeholder sa_event function
  window.sa_event = window.sa_event || function () { var a = [].slice.call(arguments); window.sa_event.q ? window.sa_event.q.push(a) : window.sa_event.q = [a] }

  // Skip initial
  Vue.use(SimpleAnalytics, {
    skip: process.env.NODE_ENV !== 'production',
    domain: process.env.VUE_APP_SA_HOST
  })

  const setTrackingMetadata = (name, value) => {
    // eslint-disable-next-line camelcase
    const { sa_metadata = {} } = window

    sa_metadata[name] = value
  }

  // Add mixin for easy tracking and setting of metadata
  Vue.mixin({
    methods: {
      $trackEvent ({ eventCategory, eventAction, eventLabel, eventValue }) {
        window.sa_event(eventAction, {
          eventCategory,
          eventLabel,
          eventValue
        })
      },
      $setTrackingMetadata: setTrackingMetadata
    }
  })

  Vue.prototype.setTrackingMetadata = setTrackingMetadata
  Vue.$setTrackingMetadata = setTrackingMetadata
}
