export const messages = {
  en: {
    message: {
      menu: {
        home: 'Home',
        courses: 'Courses',
        service: 'Services',
        contact: 'Contact'
      },
      banner: 'Academy',
      subtitle: 'Learning and development is what we do. Come learn with us!',
      courses: 'Courses',
      search: 'Search for a course...',
      start: 'Start Course',
      buy: 'Buy Course',
      signuptext: 'To get started with this course you first need to signup your email to baloo.',
      email: 'Email',
      firstname: 'Firstname',
      lastname: 'Lastname',
      getstarted: 'Get Started',
      companyname: 'Company Name',
      invoicingaddress: 'Invoicing address',
      close: 'Close',
      baloosetuptext: 'Setting up your Baloo account...',
      baloocreated: 'Your Baloo account has been created',
      balooemail: 'Check your email to start a course',
      baloorequired: 'The following fields are required',
      displayCards: 'Display courses as cards',
      displayList: 'Display courses as list',
      showmorecourses: 'Show all courses',
      showfewercourses: 'Show fewer courses',
      cancel: 'Cancel',
      numberOfCourses: 'Amount: 1 course',
      proceedToCheckout: 'Proceed to checkout',
      toPay: 'To pay',
      sek: 'SEK',
      vat: 'Inkl. 25% VAT',
      noCourses: 'There are no courses...',
      alreadyPurchased: 'Purchased',
      paymentFailed: 'Payment processing has failed',
      price: 'Price',
      free: 'Free',
      address: 'Address',
      zipcode: 'ZIP',
      city: 'City',
      country: 'Country',
      isTic: 'I accept the terms of purchase',
      paymentSuccessTitle: 'Congratulation!',
      paymentSuccessText: 'You have bought a course, further instruction is on your email',
      companyNumber: 'Company Number',
      companyEmail: 'Company E-mail',
      reference: 'Reference',
      byInvoice: 'Pay by invoice',
      discount: 'Discount',
      discountCode: 'Discount code',
      removeDiscount: 'Remove discount code',
      applyDiscount: 'Apply discount code',
      noDiscountFound: 'There is unfortunately no discount associated with the given code',
      emptyDiscountCode: 'Please enter a discount code first'
    }
  },
  sv: {
    message: {
      menu: {
        home: 'Hem',
        courses: 'Kurser',
        service: 'Tjänster',
        contact: 'Kontakta Oss'
      },
      banner: 'Academy',
      subtitle: 'Lärande och kompetensutveckling är vad vi jobbar med. Utvecklas med oss!',
      courses: 'Kurser',
      search: 'Sök på en kurs...',
      start: 'Starta Kurs',
      buy: 'Köp Kurs',
      signuptext: 'För att starta kursen ber vi dig skriva in din e-mail och namn.',
      email: 'E-post',
      firstname: 'Förnamn',
      lastname: 'Efternamn',
      getstarted: 'Kom Igång',
      companyname: 'Företagsnamn',
      invoicingaddress: 'Fakturaadress',
      close: 'Stäng',
      baloosetuptext: 'Skapar ditt Baloo konto...',
      baloocreated: 'Ditt Baloo konto har skapats',
      balooemail: 'Kolla din e-post för att starta en kurs',
      baloorequired: 'Följande textrutor får ej vara tomma',
      displayCards: 'Visa kurser som kort',
      displayList: 'Visa kurser som en lista',
      showmorecourses: 'Visa alla kurser',
      showfewercourses: 'Visa färre kurser',
      cancel: 'Avbryt',
      numberOfCourses: 'Antal 1 kurs',
      proceedToCheckout: 'Fortsätt till kassan',
      toPay: 'Att betala',
      sek: 'kr',
      vat: 'Moms (25%)',
      noCourses: 'Det finns just nu inga kurser tillgängliga...',
      alreadyPurchased: 'Köpt',
      paymentFailed: 'Betalningen har tyvärr misslyckats',
      price: 'Pris',
      free: 'Gratis',
      address: 'Adress',
      zipcode: 'Postnummer',
      city: 'Stad',
      country: 'Land',
      isTic: 'Jag godkänner köpvillkoren',
      paymentSuccessTitle: 'Grattis!',
      paymentSuccessText: 'Du har köpt en kurs, ytterligare instruktioner finns på din e-post',
      companyNumber: 'Organisationsnummer',
      companyEmail: 'Faktura e-mail',
      reference: 'Referens',
      byInvoice: 'Faktureras via företag',
      discount: 'Rabatt',
      discountCode: 'Rabattkod',
      removeDiscount: 'Ta bort rabattkod',
      applyDiscount: 'Applicera rabattkod',
      noDiscountFound: 'Tyvärr finns ingen rabatt med den angivna koden',
      emptyDiscountCode: 'Var god skriv in en rabattkod först'
    }
  }
}
