<template>
  <div :class="{ blackout: dialog }">
    <v-dialog
      v-model="dialog"
      width="600"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="dialog = false"
      :persistent="dataSubmitted"
    >
      <v-card>
        <v-img height="200" :src="courseInfo.backgroundImageUrl"></v-img>
        <v-card-title
          class="title text-break"
          :class="{
            'font-italic': !courseInfo.name,
            'grey--text': !courseInfo.name,
          }"
        >
          {{ courseInfo.name }}
        </v-card-title>
        <v-card-text align="left">
          <text-expander
            :key="courseInfo.courseId"
            class="px-2"
            font-size-class="body-2"
            :expand-character-limit="10"
          >
            <div class="subtitle pb-2" v-html="courseInfo.description" />
          </text-expander>
          <v-divider />
        </v-card-text>
        <v-card-text v-if="!dataSubmitted">
          <div class="pb-3">{{ $t("message.signuptext") }}</div>
          <v-alert
            type="error"
            class="py-1"
            color="red"
            :value="errors.length > 0"
          >
            {{ $t("message.baloorequired") }}:
            <span v-for="(error, index) in errors" :key="index">
              {{ error }}
            </span>
          </v-alert>
          <v-flex
            style="display: flex; flex-direction: column; align-items: center"
            xs12
            class="pt-2"
          >
            <v-text-field
              v-model="email"
              class="pa-0"
              :label="$t('message.email')"
              style="width: 90%; flex-grow: 0"
              name="email"
            ></v-text-field>
            <v-text-field
              v-model="firstName"
              class="pa-0"
              :label="$t('message.firstname')"
              style="width: 90%; flex-grow: 0"
              name="firstname"
            ></v-text-field>
            <v-text-field
              v-model="lastName"
              class="pa-0"
              :label="$t('message.lastname')"
              style="width: 90%; flex-grow: 0"
              name="lastname"
            ></v-text-field>
            <v-text-field
              v-model="companyName"
              class="pa-0"
              :label="$t('message.companyname')"
              style="width: 90%; flex-grow: 0"
              name="company"
            ></v-text-field>
            <v-text-field
              v-model="address"
              v-show="isCost"
              class="pa-0"
              :label="$t('message.address')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-text-field
              v-model="zipcode"
              v-show="isCost"
              class="pa-0"
              :label="$t('message.zipcode')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-text-field
              v-model="city"
              v-show="isCost"
              class="pa-0"
              :label="$t('message.city')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-text-field
              v-model="country"
              v-show="isCost"
              class="pa-0"
              :label="$t('message.country')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-text-field
              v-model="discountCode"
              v-show="isCost && hasDiscounts"
              class="pa-0"
              :label="$t('message.discount')"
              style="width: 90%; flex-grow: 0"
            ></v-text-field>
            <v-checkbox
              v-model="isTic"
              v-show="isCost"
              :label="$t('message.isTic')"
              style="color: #0d47a1"
              color="primary"
            ></v-checkbox>
            <!--            <v-text-field
              v-model="invoicingAddress"
              class="pa-0"
              :label="$t('message.invoicingaddress')"
              style="width:90%; flex-grow:0;"
            ></v-text-field>-->
          </v-flex>
        </v-card-text>
        <v-card-actions v-if="!dataSubmitted">
          <v-spacer />
          <v-btn id="cancel" flat @click="dialog = false">
            {{ $t("message.cancel") }}
          </v-btn>
          <v-btn
            id="buy"
            color="blue"
            class="white--text"
            @click="validateUser()"
          >
            {{ $t("message.getstarted") }}
          </v-btn>
        </v-card-actions>
        <div v-if="dataSubmitted" class="pb-4" style="text-align: center">
          <v-card-text v-show="!hasLoaded" class="headline">
            {{ $t("message.baloosetuptext") }}
          </v-card-text>
          <v-progress-circular
            v-show="!hasLoaded"
            indeterminate
            color="primary"
            class="mb-3"
          ></v-progress-circular>

          <v-icon v-show="hasLoaded" color="green" x-large class="mt-3"
            >done</v-icon
          >
          <v-card-text v-show="hasLoaded" class="headline text-xs-center">
            {{ $t("message.baloocreated") }}
          </v-card-text>
          <v-card-text v-show="hasLoaded" class="title pt-0">
            {{ $t("message.balooemail") }}
          </v-card-text>
          <v-btn
            v-show="hasLoaded"
            @click="dialog = false"
            color="success green"
            class="mb-3"
            >Okay</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextExpander from './TextExpander'

export default {
  name: 'CourseDialog',
  components: {
    TextExpander
  },
  props: {
    courseInfo: {
      type: Object,
      required: true
    },
    hasDiscounts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      dataSubmitted: false,
      hasLoaded: false,
      email: '',
      firstName: '',
      lastName: '',
      companyName: '',
      address: '',
      zipcode: '',
      city: '',
      country: '',
      discountCode: '',
      isTic: false,
      invoicingAddress: '',
      nameMissing: false,
      errors: []
    }
  },
  computed: {
    id () {
      return this.courseInfo.courseId
    },
    purchaserData () {
      const { purchaserData } = this.courseInfo
      return purchaserData
    },
    isCost () {
      return this.courseInfo.price
    }
  },
  methods: {
    open () {
      if (this.purchaserData) {
        this.firstName = this.purchaserData.firstName
        this.lastName = this.purchaserData.lastName
        this.email = this.purchaserData.email
        this.sendUser()
      }
      this.dialog = true
    },
    sendUser () {
      this.dataSubmitted = true
      const targetRequest = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        contentId: this.id,
        paymentId: 'free'
      }
      this.axios
        .post('targets', targetRequest)
        .then(response => {
          this.dataSubmitted = true
          this.hasLoaded = true
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.dataSubmitted = false
          this.hasLoaded = false
          this.errors.push('Something went wrong')
        })
    },
    validateUser () {
      this.errors = []
      if (
        this.firstName &&
        this.lastName &&
        this.email &&
        (this.isTic || !this.isCost)
      ) {
        this.sendUser()
      }

      if (!this.email) {
        this.errors.push(this.$i18n.locale === 'en' ? 'Email' : 'E-post')
      }

      if (!this.firstName) {
        this.errors.push(this.$i18n.locale === 'en' ? 'Firstname' : 'Förnamn')
      }

      if (!this.lastName) {
        this.errors.push(this.$i18n.locale === 'en' ? 'Lastname' : 'Efternamn')
      }

      if (!(this.isTic || !this.isCost)) {
        this.errors.push(
          this.$i18n.locale === 'en'
            ? 'I accept the terms of purchase'
            : 'Jag godkänner köpvillkoren'
        )
      }
    }
  },
  watch: {
    dialog (val) {
      val = !val
      this.errors = []

      if (val) {
        this.$trackEvent({
          eventCategory: 'course',
          eventAction: 'view',
          eventLabel: this.id
        })
      }
    }
  }
}
</script>

<style scoped>
.blackout {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  z-index: 2;
}
</style>
