<template>
  <div id="course-container" class="course-container">
    <div v-if="showSearchField">
      <div style="display: flex">
        <v-text-field
          label="Solo"
          :placeholder="$t('message.search')"
          solo
          :style="{ width: returnInputWidth(), height: 50 + 'px' }"
          v-model="inputText"
        >
        </v-text-field>
      </div>
    </div>
    <div
      class="courses-container main-container"
      v-if="show"
      :style="{ width: returnPageWidth() }"
    >
      <Course
        v-for="(course, index) in filteredCourses.slice(
          0,
          showAll ? filteredCourses.length : 6
        )"
        :courseinfo="courseInfo(course)"
        :key="index"
        @openPaymentDialog="openDibsDialog($event)"
        @payCompleted="closeDibsDialog($event)"
        :has-discounts="hasDiscounts"
      />
    </div>
    <div class="courses-container" v-if="!show">
      <course-table
        v-for="(course, index) in filteredCourses.slice(
          0,
          showAll ? filteredCourses.length : 6
        )"
        :courseinfo="courseInfo(course)"
        :key="'B' + index"
        @openPaymentDialog="openDibsDialog($event)"
        @payCompleted="closeDibsDialog($event)"
        :has-discounts="hasDiscounts"
      />
    </div>
    <v-btn
      class="mt-4"
      v-show="filteredCourses.length > 6"
      @click="showAll = !showAll"
      >{{
        showAll ? $t("message.showfewercourses") : $t("message.showmorecourses")
      }}</v-btn
    >

    <v-dialog v-model="dibsPaymentDialog">
      <div id="dibs-frame" />
    </v-dialog>

    <course-dialog
      v-if="purchasedCourseInfo"
      ref="purchasedCourseDialog"
      :course-info="purchasedCourseInfo"
      :has-discounts="hasDiscounts"
    />

    <v-snackbar v-model="paymentFailed">
      {{ $t("message.paymentFailed") }}
    </v-snackbar>

    <v-dialog v-if="dialogCongrats" width="35%" value="true">
      <v-card>
        <v-card-title class="green lighten-2">
          {{ $t("message.paymentSuccessTitle") }}
        </v-card-title>
        <v-card-text>
          <div class="pb-3">
            <v-icon color="green darken-2" x-large>check</v-icon>
            {{ $t("message.paymentSuccessText") }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            class="white--text"
            text
            @click="dialogCongrats = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Course from './Course'
import CourseTable from './CourseTable'
import CourseDialog from './CourseDialog'

export default {
  data () {
    return {
      paymentData: {},
      courses: [],
      inputText: '',
      dialog: true,
      dialogCongrats: false,
      false: false,
      show: true,
      showAll: false,
      dibsPaymentDialog: false,
      categories: [],
      selectedCategories: [],
      showSearchField: true,
      hasDiscounts: false
    }
  },
  components: {
    CourseDialog,
    Course,
    CourseTable
  },
  created () {
    if (new URLSearchParams(window.location.search).has('hideSearchField')) {
      this.showSearchField = false
    }
  },
  mounted () {
    this.axios.get('discounts').then(({ data: { hasDiscounts } }) => {
      this.hasDiscounts = hasDiscounts
    })
    this.axios.get('courses').then((response) => {
      this.courses = response.data.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
      let setOfCoursesTypes = new Set(
        this.courses.flatMap((value) => value.categories)
      )
      this.categories = Array.from(setOfCoursesTypes)
      if (this.paymentId && !this.paymentFailed) {
        this.axios
          .get(`payment/${this.paymentId}/consumer`)
          .then((response) => {
            this.paymentData = response.data
            setTimeout(() => {
              if (this.purchasedCourseInfo) {
                this.$refs['purchasedCourseDialog'].open()
              }
            }, 0)
          })
      }
    })
  },
  computed: {
    paymentId () {
      const { paymentId = '' } = this.$route.query
      return paymentId
    },
    paymentFailed () {
      const { paymentFailed = false } = this.$route.query
      return paymentFailed
    },
    filteredCourses () {
      let filter = this.courses.filter((course) =>
        course.name.toLowerCase().includes(this.inputText.toLowerCase())
      )
      if (this.selectedCategories.length > 0) {
        filter = filter.filter((course) => {
          for (const c of course.categories) {
            if (this.selectedCategories.includes(c)) return true
          }
        })
      }
      return filter
    },
    purchasedCourseInfo () {
      const { courseId } = this.paymentData
      const course =
        this.courses.find((course) => course.courseId === courseId) || null
      if (courseId && course) {
        return {
          ...course,
          forFree: this.forFree(course),
          price: this.price(course),
          purchaserData: this.purchaserData(course)
        }
      } else {
        return null
      }
    }
  },
  methods: {
    handleFilters (event) {
      this.selectedCategories = event
    },
    returnInputWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '220px'
        case 'sm':
          return '320px'
        case 'md':
          return '420px'
        case 'lg':
          return '620px'
        case 'xl':
          return '720px'
      }
    },
    returnPageWidth () {
      if (window.location.hostname === 'www.baloolearning.se') return '150%'
      if (
        window.location.hostname === 'awesomecompany-demo.baloolearning.se' ||
        window.location.hostname === 'localhost'
      ) {
        return '100%'
      } else return '100%'
    },
    cancelPayment () {
      this.axios
        .post('cancel-payment', {
          paymentId: this.paymentData.id,
          amount: this.paymentData.amount
        })
        .finally((_) => {
          document.getElementById('dibs-frame').innerHTML = ''
        })
    },
    openDibsDialog (payment) {
      this.paymentData = payment
      this.dibsPaymentDialog = true
    },
    closeDibsDialog (result) {
      this.dibsPaymentDialog = false
      document.getElementById('dibs-frame').innerHTML = ''
      if (result) {
        let vm = this
        this.dialogCongrats = true
        setTimeout(() => {
          vm.dialogCongrats = false
        }, 8000)
      }
    },
    forFree (course) {
      return false
    },
    price (course) {
      // this method was created only for test
      return course.name.length * 17
    },
    courseInfo (course) {
      return {
        ...course,
        forFree: this.forFree(course),
        purchaserData: this.purchaserData(course)
      }
    },
    purchaserData (course) {
      const { courseId, firstName, lastName, email } = this.paymentData
      if (courseId === course.courseId) {
        return { firstName, lastName, email }
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
.course-container {
  padding: 0px 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courses-container {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
}

.course-type-selector {
  height: 90px;
  margin-top: -20px;
}

.banner {
  color: #2aa8ac;
  margin: 0px 0 30px 0;
  font-size: xxx-large;
}

.main-container {
  max-width: 1000px;
  padding: 0;
}
</style>

<style>
.container {
  padding: 0;
  max-width: 100%;
}
</style>
