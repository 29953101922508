<template>
  <v-card width="320">
    <CourseDialog
      ref="CourseDialogComp"
      :course-info="courseinfo"
      :has-discounts="hasDiscounts"
    />
    <buy-course-dialog
      v-model="buyDialog"
      :course-info="courseinfo"
      @onlyClose="buyDialog = false"
      @close="
        buyDialog = false;
        $emit('payCompleted', false);
      "
      @openPaymentDialog="$emit('openPaymentDialog', $event)"
      @payCompleted="$emit('payCompleted', $event)"
      :has-discounts="hasDiscounts"
    />
    <v-img
      :src="this.courseinfo.backgroundImageUrl"
      aspect-ratio="1.75"
    ></v-img>

    <v-card-title class="course-title">
      <div>
        <h3 class="headline mb-0">
          {{ this.courseinfo.name }}
        </h3>
      </div>
    </v-card-title>

    <v-card-actions>
      <v-btn v-if="isFree" flat @click="openDialog()" color="blue">{{
        $t("message.start")
      }}</v-btn>
      <v-btn v-else flat @click="openBuyDialog()" color="blue">{{
        $t("message.buy")
      }}</v-btn>
      <div v-show="isFree">
        <span class="bold-font">{{ $t("message.price") }}:</span
        ><span class="free-color">{{ $t("message.free") }}</span>
      </div>
      <div v-show="!isFree">
        <span class="bold-font">{{ $t("message.price") }}:</span
        ><span class="price">{{ courseinfo.price | filterAmount }}</span>
        {{ $t("message.sek") }}
      </div>

      <v-spacer></v-spacer>
      <v-btn icon @click="show = !show">
        <v-icon v-if="show">keyboard_arrow_up</v-icon>
        <v-icon v-else>keyboard_arrow_down</v-icon>
      </v-btn>
    </v-card-actions>
    <v-slide-y-transition>
      <v-card-text v-html="this.courseinfo.description" v-show="show">
        {{ this.courseinfo.description }}
      </v-card-text>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import CourseDialog from './CourseDialog'
import BuyCourseDialog from './BuyCourseDialog'

export default {
  name: 'Course',
  components: {
    BuyCourseDialog,
    CourseDialog
  },
  props: {
    courseinfo: {
      type: Object,
      required: true
    },
    hasDiscounts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      buyDialog: false
    }
  },
  computed: {
    purchased () {
      const { purchaserData } = this.courseinfo
      return purchaserData
    },
    isFree () {
      return !this.courseinfo.price
    }
  },
  methods: {
    openDialog () {
      this.$refs['CourseDialogComp'].open()
    },
    openBuyDialog () {
      this.buyDialog = true
    }
  }
}
</script>

<style scoped>
.v-card {
  margin: 20px 5px;
}

.v-card__text {
  text-align: start;
  font-size: 1.2em;
}

.course-title {
  padding-bottom: 0px;
  min-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bold-font {
  /*font-weight: bold;*/
  padding-left: 8px;
}
.free-color {
  color: green;
  padding-left: 8px;
}
.price {
  /*font-size:larger;*/
  /*font-style: italic;*/
  padding-left: 8px;
}
</style>
