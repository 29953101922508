import Vue from 'vue'
import Vuetify from 'vuetify'
import VueI18n from 'vue-i18n'
import vueAxios from 'vue-axios'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { messages } from './locale'
import nets from './payment'
import SimpleAnalyticsPlugin from './plugins/simple-analytics'

Vue.use(Vuetify)
Vue.use(VueI18n)
Vue.use(vueAxios, axios.create({
  baseURL: process.env.VUE_APP_COURSE_CATALOGUE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Origin-Customer': nets.getCustomerHost()
    // 'Origin-Customer': (window.location !== window.parent.location) ? new URL(document.referrer).host : document.location.host
  },
  timeout: 25 * 1000
}))

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'sv',
  messages
})

SimpleAnalyticsPlugin(nets.getCustomerHost())

Vue.mixin({
  filters: {
    filterAmount: function (value) {
      if (!value || value < 1) return ''
      return (value / 100).toFixed(0)
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
