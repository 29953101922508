<template>
  <div class="text-expander" :class="{ expanded: useExpandStyles }">
    <div class="text" :class="fontSizeClass" :style="textStyles">
      <slot ref="slot"/>
    </div>

    <v-btn v-if="!alwaysExpanded" icon class="expander" @click.native="expanded = !expanded">
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'TextExpander',
  props: {
    minHeight: {
      type: String,
      required: false,
      default: '6em'
    },
    fontSizeClass: {
      type: String,
      default: 'body-1'
    },
    expandCharacterLimit: {
      type: Number,
      default: 150
    }
  },
  data () {
    return {
      textHeight: this.minHeight,
      alwaysExpanded: false,
      expanded: false,
      animationId: null,
      style: {}
    }
  },
  mounted () {
    const [defaultSlot] = this.$slots.default

    if (!defaultSlot) {
      return
    }

    const innerText = defaultSlot.elm.innerText || ''

    if (innerText.length < this.expandCharacterLimit) {
      this.alwaysExpanded = true
    }
  },
  computed: {
    icon () {
      return this.expanded ? 'expand_less' : 'expand_more'
    },
    useExpandStyles () {
      return this.alwaysExpanded || this.expanded
    },
    textStyles () {
      return {
        overflow: this.useExpandStyles ? 'visible' : 'hidden',
        height: this.useExpandStyles ? 'auto' : this.minHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-expander {
    margin: 10px 0;
    position: relative;

    &:not(.expanded) .text:after {
      content: '';
      display: block;
      position: absolute;
      pointer-events: none;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 56%, rgba(255, 255, 255, 1) 100%);
      z-index: 1;
    }

    &.expanded .text {
      padding-bottom: 20px;
    }
  }

  .text {
    transition: height 235ms ease;

    :global(p:first-of-type) {
      margin-top: 0;
    }

    :global(p:last-of-type) {
      margin-bottom: 0;
    }
  }

  .expander {
    position: absolute !important;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
</style>
